body {
  margin: 0;
  height: 100vh;
  position: flex;
  background-color: "#ebebeb";
}

h3,
h4 {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nbi-table tbody.MuiTableBody-root > tr:last-of-type button {
  display: none;
}
